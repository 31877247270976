.Search {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 1000;

    &-Wrapper {
        background-color: #fff;
        @include max-width-height;

        overflow: hidden;
        overflow: scroll;
        &::-webkit-scrollbar {
             width: 0px;  /* Remove scrollbar space */
            background: transparent;  /* Optional: just make scrollbar invisible */
         }

        &-input-holder {
           position: relative;
           border-bottom: 1px solid rgb(190, 187, 187);
           height: 50px;
           width: 100%;
           display: flex;

           &__back {
               //background-color: red;
               height: 100%;
               width: 60px;
               @include flex-center;
               cursor: pointer;
               transition: all .25s ease-in-out;
               &:hover {
                   transform: translateX(-3px);
               }
           }

           &__input {
                //background-color: rgb(129, 88, 88);
                height: 100%;
                width: 100%;
           }

        }

        &-parfum-box {
            //background-color: red;
            @include max-width-height;
            padding-top: 15px;
        }
    }

}

.SearchItem {
    display: grid;
    grid-template-columns: 70px 1fr;
    padding: 0 10px 0 10px;
    height: 70px;
    margin-top: 15px;

    &-img {
        height: 70px;

        & img {
            height: 100%;
            width: 100%;
        }

    }

    &-text {
        padding-left: 20px;
        background-color: rgb(255, 255, 255);

        &:hover{
            background-color: rgb(236, 236, 236);
        }

        & p {
            text-transform: uppercase;
        }

        &-brand {
            transform: translateY(5px);
            font-size: 12px;
            color: rgba(0, 0, 0, .70)
        }

        &-name {
            transform: translateY(-10px);
            font-size: 17px;
            color: rgba(0, 0, 0, 1)
        }
    }
}

@keyframes searchOpening {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}