
@mixin bottom-line {
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, .5);
    width: 100%;
    height: 1px;
    border-radius: 100%;

    @media screen and (min-width: 650px) {
        display: none;
    }
}

.Parfum {
    position: relative;
    background-color: #fff;
    width: 100%;
    padding-top: 50px;
    height: max-content;
    padding-bottom: 70px;

    display: flex;
    flex-direction: column;

    &-mainWrapper {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        position: relative;

        @media screen and (min-width: 650px) {
            box-sizing: border-box;
            padding: 70px 25px 0 25px;
            display: grid;
            grid-template-columns: repeat(8, 1fr);
            grid-template-rows: repeat(8, 55px);
        }
    
        @media screen and (min-width: 900px) {
            padding: 100px 150px 0 150px;
        }
    
        @media screen and (min-width: 1100px) {
            padding: 100px 150px 0 320px;
        }
    }



    &-bottom-line {
        @include bottom-line;
    }
}

.ImageAndNameParfum {

    position: relative;

    @media screen and (min-width: 650px) {
        grid-column: 1 / span 4;
        grid-row: 1 / span 3;
    }

    &-discount {
        position: absolute;
        top: 5px;
        height: 5px;
        width: 20px;
        height: 20px;
        background-color: red;
    }


    &-img-wrapper {
        display: flex;
        justify-content: center;

        & img {
           width: 230px;
           height: 280px;

           @media screen and (min-width: 650px) {
            width: 300px;
            height: 350px;
        }
    }
}

    &-name {
        text-align: center;

        & p {
            color: $gold-hard;
            font-size: 23px;
            text-transform: uppercase;
            font-weight: 700;
        }
    }

    &-bottom-line {
        @include bottom-line;
    }
    
}

.ParfumQuanity {
    position: relative;
    padding-top: 35px;
    font-weight: 700;
    color: rgba(0, 0, 0, .9);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 35px;

    @media screen and (min-width: 650px) {
        grid-column: 5 / -1;
        grid-row: 5 / span 2;
        margin-bottom: 0;
        transform: translateY(-35px);
    }

    &-rating {
        margin-bottom: 50px;
        display: flex;
        align-items: center;

        &-rating {
            color: black;
            font-size: 20px;
            margin-left: 10px;
        }
    }

    &-quanity {
        position: absolute;
        left: 5%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 6px;
        align-items: center;
        font-size: 12px;

        @media screen and (min-width: 650px) {
            left: 0;
            bottom: 75px;
        }

        & div {
            @include flex-center;
            padding: 5px;
            transition: all .15s ease-in;
            cursor: pointer;
            &:hover { background-color: #eee; }

            @media screen and (min-width: 650px) {
                padding: 3px;
            }
        }
    }

    &-price {
        position: relative;
        color: rgb(80, 78, 78);
        line-height: 1;
        font-size: 17px;
        transform: translateX(50%);
    
        @media screen and (min-width: 350px) {
            transform: translateX(0);
        }

        @media screen and (min-width: 650px) {
            position: absolute;
            bottom: 130px;
            left: 0;
            font-size: 19px;
        }

        & span {
            color: $gold-hard;
            font-size: 23px;
            transition: all .15s ease-in;
        }
    }

}

.ArticalHolder {

    @media screen and (min-width: 650px) {
       grid-column: 1 / -1;
       grid-row: 4 / span 2;
    }
}

.ArticalInfo {
    width: 100%;
    border-bottom: .1px solid rgba(0, 0, 0, .2);

    @media screen and (min-width: 900px) {
       width: 85%;
       margin-left: 10%;
    }

    &-header {
        cursor: pointer;
        display: flex;
        align-items: center;
        padding-left: 15px;
        border-bottom: .1px solid rgba(0, 0, 0, .2);
        
        & p {
            font-weight: 600;
            margin-left: 15px;
            color: rgba(0, 0, 0, .88);
            @include jost-font;
            font-size: 14px;
            letter-spacing: 1px;
        }
    }

    &-description {

        display: flex;
        flex-direction: column;
        list-style: none;
        line-height: 1.7;
        transition: all 1s ease-in;

        & p {
            color: rgba(0,0,0, .9);
            line-height: 1.4;
        }

    }
}


.ParfumRating {
    @include flex-center;
    padding-left: 15px;
    justify-content: start;
    margin-bottom: 25px;

    @media screen and (min-width: 650px) {
        margin-bottom: 0;
        padding-left: 0;
        grid-column: 5 / span 3;
        grid-row: 6 / span 1;
        transform: translateY(-20px);
     }

    & p {
        font-family: 'Roboto', sans-serif;
        margin-left: 12.5px;
        letter-spacing: 1.7px;
        color: #333;
    }
}

@keyframes animStars {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.4);
    }

    100% {
        transform: scale(1);
    }
} 

