.lds-dual-ring {
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-40%, -50%);
    display: inline-block;
    width: 80px;
    height: 80px;
  }
  .lds-dual-ring:after {
    content: " ";
    display: block;
    width: 30px;
    height: 30px;
    margin: 8px;
    border-radius: 50%;
    border: 3px solid $gold-hard;
    border-color: $gold-hard transparent $gold-hard transparent;
    animation: lds-dual-ring .75s linear infinite;
  }
  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  