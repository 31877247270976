$gold-lighter: #e4c7a5; 
$gold-medium: #cab49b;
$gold-hard: #9c8265;
$dark-background: #302e2f;

@font-face {
    font-family: 'Neutra';
    src: url('../assets/fonts/Neutra-Text-Alt_32104.ttf');
    font-weight: 100;
    font-style: normal;
}