.Header {
    position: relative;
    height: 450px;
    margin-top: -53px;

    @media screen and (min-width: 600px) {
      height: 550px;
    }

    @media screen and (min-width: 900px) {
      height: 93vh;
     }

    & img {
      @include max-width-height;
      filter: brightness(50%);
    }

    &-content {
      position: absolute;
      top: 35%;
      left: 0;
      //background-color: rgba(0, 0, 0, .2);
      width: 100%;
      color: #fff;
      z-index: 10;
      
      display: flex;
      flex-direction: column; 
      align-items: center;

      @media screen and (min-width: 600px) {
        top: 250px;
       }

       @media screen and (min-width: 900px) {
         top: 330px;
         width: max-content;
         left: 65%;
       }
 
  
      & h1 {
        font-weight: 100;
        font-size: 23px;
        letter-spacing: 1.5px;

        @media screen and (min-width: 900px) {
          font-size: 28px;
        }

      }
    }

  
}