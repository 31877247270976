.Faq {
    background-color: #fff;
    font-family: 'Roboto', sans-serif;
    padding: 15px 15px; 
    color: rgba(0, 0, 0, .85);

    &-question {
        &:not(:first-child){ transform: translateY(-17.5px); }
    }
    
    & h4 {
        font-size: 15px;
    }

    & p {
        font-size: 15px;
        margin-left: 7px;
        color: rgba(0, 0, 0, .9);
        transform: translateY(-15px);
    }
}