body, html {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background-color: #eee;
    font-family: 'Neutra';
    scroll-behavior: smooth;
    margin-top: 25px;

    input{
         box-sizing: border-box;
         appearance: none;
         outline: none;  
         background-clip: padding-box;
         box-shadow: none !important;
         padding: 0;
     }
}


