.Preview {
    background-color: #fff;
    margin-top: 15px;
    display: grid;
    grid-template-rows: repeat(2, 300px);
    row-gap: 10px;

    @media screen and (min-width: 600px) {
        grid-template-rows: repeat(2, 400px);
      }

      @media screen and (min-width: 900px) {
        padding: 25px 40px 0 40px;
        row-gap: 0px;
        column-gap: 20px;
        grid-template-rows: repeat(1, 300px);
        grid-template-columns: repeat(2, 1fr);
      }

      @media screen and (min-width: 1100px) {
        padding: 50px 90px 0 90px;
        grid-template-rows: repeat(1, 370px);
       
      }

    &-Comp {
        position: relative;
        display: flex;
        justify-content: center;
        cursor: pointer;

        @media screen and (min-width: 900px) {
          &:hover img {
            transform: scale(1.4) translate(50px);
          }
       }

  
        & img {
            @include max-width-height;
            filter: brightness(60%);
            transition: all 7s ease-in-out;
        }
    
        @media screen and (min-width: 900px) {
            border-radius: 3px;
            overflow: hidden;
          }

        & h3 {
            position: absolute;
            bottom: 10%;
            color: #fff;
            margin: 0 auto;
            letter-spacing: 1.7px;
            font-size: 17px;
        }
    }
}