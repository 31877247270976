.Navigation {
    background: linear-gradient(227deg, rgb(37, 35, 33), rgb(22, 21, 21));
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;

    &-close {
        position: absolute;
        top: 0;
        left: 0;
        height: 50px;
        width: 50px;
        @include flex-center;
        font-size: 20px;
        color: #fff;

    }

    &-itemWrapper {
        position: absolute;
        top: 25%;
        left: 5%;
        text-transform: uppercase;

        &-item {
            color: $gold-medium;
            font-weight: 700;
            padding: 10px;
            font-size: 18px;
            margin: 2px;
        }
    }

    &-copyright {
        position: absolute;
        bottom: 10px;
        left: 50%;
        transform: translateX(-50%);
    }
}


.DesktopNav {
    @include flex-center;
    position: absolute;
    height: 100%;
    top: 0%;
    right: 20%;
    width: max-content;

    @media screen and (max-width: 650px) {
       visibility: hidden;
      }


    &-Item {
        cursor: pointer;
        & > * { margin: 0 5px; }

        @media screen and (min-width: 900px) {
            & > * { margin: 0 7px; }
           }

           &:hover p::before {
            width: 100%;
         }
        
        & p {
            position: relative;
            text-transform: uppercase;
            font-size: 14px;
            letter-spacing: .5px;
            transform: skew(-5deg);
            font-weight: 700;
            color: rgb(247, 243, 243);

            &::before {
                position: absolute;
                bottom: -6px;
                content:"";
                width: 0%;
                height: 1.5px;
                background-color: #fff;
                transition: all .25s cubic-bezier(.91,.39,.18,.04);
             }
        }

    }
}

@keyframes navigation-enter {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes navigation-close {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes close-enter {
    0% {
        transform: translateX(-100%) rotate(-90deg);
    }

    100% {
        transform: translateX(0)  rotate(0);;
    }
}

@keyframes item-enter {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
