.Catalogue {
    display: flex;
    flex-direction: column;

    &-connect {
        padding-top: 35px;
        background-color: #fff;
    }
}

.AllCatalogue {
    background-color: #fff;
}

.CataloguePreview {
    padding: 35px 7.5px 15px 7.5px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(6, max-content);
    column-gap: 7.5px;
    row-gap: 7.5px;
    justify-content: center;
    background-color: #fff;

    @media screen and (min-width: 650px) {
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(4, max-content);
     }

     @media screen and (min-width: 900px) {
        padding: 35px 50px 15px 50px;
        column-gap: 13px;
        row-gap: 10px;
     }

     @media screen and (min-width: 1100px) {
        padding: 35px 180px 15px 180px;
        column-gap: 17px;
        row-gap: 13px;
     }
}

.ParfumPreview {
    padding: 15px 0 5px 0;
    @include flex-center;
    flex-direction: column;
    border: .5px solid rgb(216, 214, 214);
    box-shadow: 0 1px 6px 0 rgba(216, 214, 214, 0.2);
    border-radius: 2.5px;
    transition: all .2s ease-in-out;
    position: relative;

    &-discount {
        position: absolute;
        top: 5px;
        right: 5px;    
        height: 35px;
        width: 35px;    
        font-size: 13px;
        @include flex-center;
        background-color: red;
        border-radius: 50%;
        color: #fff;

        @media screen and (min-width: 850px) {
            height: 45px;
            width: 45px; 
            font-size: 17px;
         }
    }

    &:hover img {
        transform: scale(1.1);
    }

    &-image {
        & img {
            transition: all .5s ease-in-out;
            height: 170px;
            width: 150px;
        }
    }

    &-brand {
        line-height: 0;
        font-size: 14px;
        text-transform: uppercase;
        transform: translateY(7px);
    }

    &-name {
        text-align: center;
        font-weight: 700;
        letter-spacing: .85px;
        color: $gold-hard;
        line-height: 1;
        font-size: 17px;
        text-transform: uppercase;
        transform: translateY(-7px);
    }

    &-price {
        margin-top: 28px;
        line-height: 0;
        font-size: 20px;
        color: rgb(54, 54, 54);
    }

    &-buttons {
        box-sizing: border-box;
        margin-top: 15px;
        width: 100%;
        height: 25px;
        display: grid;
        grid-template-columns: repeat(2 , 1fr);
        column-gap: 5px;
        padding: 0 5px;
    }
}

@keyframes optionAnim {
    0% {
        height: 0;
    }

    100% {
        height: max-content;
    }
}

 