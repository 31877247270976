.Filter {
    height: max-content;
    background-color: #fff;
    margin-top: 10px;

    &-filters {
        display: flex;
    }
    
    &-Item {
        border: .3px solid rgba(0, 0, 0, .5);
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        border-left: none;
        border-right: none;

        & p {
            color: rgba(0, 0, 0, .85);
            font-size: 13px;
            margin-right: 10px;
            cursor: pointer;
            
        }
    }

    &-options {
        transition: all .2s ease-in-out;
        background-color: #eee;
        
        &__option {
            position: relative;
            width: 100%;
            float: left;
            @include flex-center;
            cursor: pointer;

            &:hover {
                background-color: rgb(245, 244, 244);
            }

            &:last-child {
                border-bottom: .15px solid rgb(190, 190, 190);
            }

            & p {
                line-height: 0;
            }
        }
    }

    
}


.FilterPath {
    padding: 0 3.5px 5px 3.5px;
    
    &-Item {

     @include flex-center;
     font-size: 12px;
     color: black;
     float: left;
     margin-left: 5px;
     margin-top: 5px;
     background-color: #fff;
     padding: 8px 10px;
     border: .5px solid rgb(129, 127, 127);
     border-radius: 3px;

     &-close {
         font-size: 12.5px;
         margin-left: 6.5px;
         font-weight: 500;
         color: rgba(0, 0, 0, .8);
     }
}
    
}