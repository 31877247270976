.Info {
    background-color: #fff;
    height: 100px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    align-items: center;
    justify-items: center;
    padding: 0 10px;

    @media screen and (min-width: 600px) {
       display: flex;
       height: max-content;
       padding: 5px 0;
       border-bottom: .5px solid rgb(172, 172, 172);
      }

    &-Item {
     color: rgba(0, 0, 0, 1);
     display: grid;
     width: 100%;
     grid-template-columns: repeat(2, max-content);
     column-gap: 6px;
     align-items: center;
     justify-content: center;

     & p {
         line-height: 1.7;
         font-size: 10px;
         font-family: 'Roboto', sans-serif;
         font-weight: 600;
         letter-spacing: .5px;
         color: rgba(0, 0, 0, .75);
     }
     
    }

}